@use './../abstracts' as *;

$container-default-gutter: 2rem;
$container-gutters: (
	"sm": 1rem,
	"df": $container-default-gutter,
	"md": 3rem,
	"lg": 4rem,
);

$container-xxl-max-width: 1336px;
// 1290px
$container-loose-max-width: 1110px;
$container-tight-max-width: 1050px;
$container-skinny-max-width: 927px;
$container-close-max-width: 850px;

.container {
	--bs-gutter-x: #{$container-default-gutter};

	>.row {
		--bs-gutter-x: #{$container-default-gutter};
	}

	// for each $container-gutters
	@each $g,
	$value in $container-gutters {
		&.gutter-#{$g} {
			--bs-gutter-x: #{$value};

			>.row {
				--bs-gutter-x: #{$value};
			}

			--gutter-x: #{$value};

			/* --gutter: #{$value};

			padding: {
				left: var(--gutter);
				right: var(--gutter);
			} */
		}
	}

	@include breakpoint-min-xxl {
		max-width: $container-xxl-max-width;
	}

	&-tight {
		@include breakpoint-min-xxl {
			max-width: $container-tight-max-width;
		}
	}

	&-skinny {
		@include breakpoint-min-lg {
			// max-width: calc(#{$container-skinny-max-width} + var(--bs-gutter-x));
			// max-width: calc(#{$container-skinny-max-width} + calc(var(--prova) * 2));
			max-width: calc(#{$container-skinny-max-width} + calc(var(--bs-gutter-x) * 2));
		}
	}

	&-loose {
		@include breakpoint-min-lg {
			// max-width: calc(#{$container-loose-max-width} + var(--bs-gutter-x));
			max-width: calc(#{$container-loose-max-width} + calc(var(--bs-gutter-x) * 2));
		}
	}

	&-close {
		max-width: calc(#{$container-close-max-width} + calc(var(--bs-gutter-x) * 2));
	}

	@include breakpoint-min-xxl {
		.container {
			max-width: 1322px;
		}
	}
}