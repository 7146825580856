@use './../abstracts' as *;

// calendar
$calendar-gap: 0.5rem;
$calendar-lg-gap: 1rem;
$calendar-font-size: 0.875rem;
$calendar-font-weight: $medium;

// calendar item
$calendar-item-color: map-get($main-colors, secondary);
$calendar-item-border-radius: 0.5rem;
$calendar-item-bg-color: rgba(#8fd4f5, 30%);
$calendar-item-font-size: 0.75rem;
$calendar-item-width: 3.0625rem;
$calendar-item-height: 2.1875rem;

$calendar-item-lg-width: 4.0625rem;
$calendar-item-lg-height: 2.75rem;


// calendar item - status active
$calendar-item-active-border-width: 0.1875rem;
$calendar-item-active-border-color: map-get($main-colors, primary);

// calendar item hour
$calendar-item-hour-font-size: 0.875rem;

.calendar {
	text-align: center;

	&-grid {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	&-hours,
	&-days {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: $calendar-gap;
		justify-items: center;

		@include breakpoint-min-lg {
			gap: $calendar-lg-gap;
		}
	}

	&-days {
		font-size: $calendar-font-size;
		font-weight: $calendar-font-weight;
	}

	&-item {
		display: inline-grid;

		width: $calendar-item-width;
		height: $calendar-item-height;

		border-radius: $calendar-item-border-radius;

		font-size: $calendar-item-font-size;
		font-weight: $calendar-font-weight;
		line-height: 1;

		color: $calendar-item-color;
		background-color: $calendar-item-bg-color;

		text-align: center;

		@include breakpoint-min-lg {
			width: $calendar-item-lg-width;
			height: $calendar-item-lg-height;
		}

		input,
		label {
			grid-area: 1 / 1;
		}

		label {
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			padding: 0.1875rem 0 0.125rem 0;

			border: calc($calendar-item-active-border-width / 2) solid transparent;
			outline: calc($calendar-item-active-border-width / 2) solid transparent;

			border-radius: $calendar-item-border-radius;

			transition: border 0.2s ease-in-out, outline 0.2s ease-in-out;
		}

		input {
			width: 100%;
			height: 100%;
			opacity: 0;

			&:checked~label {
				border-color: $calendar-item-active-border-color;
				outline-color: $calendar-item-active-border-color;
			}
		}

		&-hour {
			font-size: $calendar-font-size;
		}
	}
}