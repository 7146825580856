@use './../abstracts' as *;

$box-item-list-border-color: rgba(map-get($main-colors, "secondary"), 20%);

.box-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include breakpoint-min-lg {
        gap: 1.5rem;
    }

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }

    &-preview {
        display: none;

        margin: {
            left: calc(var(--shadow-box-px) * -1);
            right: calc(var(--shadow-box-px) * -1);
        }

        img {
            object-fit: cover;
        }

        @include breakpoint-min-lg {
            display: block;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        > :not(:first-child) {
            &:before {
                content: '';

                width: 100%;
                height: 1px;

                background-color: $box-item-list-border-color;
            }
        }
    }

    &-body {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        gap: 1rem;

        &--three-columns {
            grid-template-columns: 2fr auto 4rem;
        }

        &-info {
            order: 1;
        }

        &-number-item {
            order: 2;
        }

        &-price {
            order: 3;
        }

        &-number-item,
        &-price {
            text-align: right;
        }
    }
}