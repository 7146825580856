@use './../abstracts' as *;

$rounded-box-padding: 1rem;
$rounded-box-border-radius-active: 2.5rem;

$rounded-box-lg-padding: 2rem;

// sm
$rounded-box-sm-border-radius: 0.375rem;

// picture
$rounded-box-picture-min-height: 23.125rem;

// lg
$rounded-box-picture-lg-min-height: 37.5rem;

// caption
$rounded-box-caption-gap: 1.5rem;

// body
$rounded-box-body-max-width: 80rem;

.rounded-box {
	padding: $rounded-box-padding;
	position: relative;
	z-index: 0;
	overflow: hidden;

	@include breakpoint-min-lg {
		padding: $rounded-box-lg-padding;
	}

	&.rounded-sm {
		border-radius: $rounded-box-sm-border-radius;
	}

	&-body {
		width: 100%;
		max-width: $rounded-box-body-max-width;
		margin-inline: auto;
		text-align: center;

		align-self: flex-end;

		&:after {
			content: "";
			z-index: -1;

			position: absolute;

			left: 0;
			bottom: 0;

			width: 100%;
			height: 50%;

			background-image: linear-gradient(180deg,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 0.53) 100%);
		}

		@include breakpoint-min-lg {
			align-self: center;
			text-align: right;

			&:after {
				left: unset;
				right: 0;
				top: 0;

				width: 80%;
				height: 100%;

				background-image: linear-gradient(-89deg,
						rgba(0, 0, 0, 0.65) 0%,
						rgba(0, 0, 0, 0) 99%);
			}
		}
	}

	&-caption {
		display: flex;
		flex-direction: column;
		gap: $rounded-box-caption-gap;

		margin: {
			left: auto;
			right: auto;
		}

		@include breakpoint-min-lg {
			margin-right: unset;
			max-width: 35.4375rem;
		}
	}

	&.rounded {
		&-box {
			&-picture {
				display: flex;
				min-height: $rounded-box-picture-min-height;

				@include breakpoint-min-lg {
					min-height: $rounded-box-picture-lg-min-height;
				}

				.rounded-box {
					&-bg {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;

						width: 100%;
						height: 100%;

						object-fit: cover;

						z-index: -10;
					}
				}
			}
		}

		&-top {
			&-left {
				border-top-left-radius: $rounded-box-border-radius-active;
			}

			&-right {
				border-top-right-radius: $rounded-box-border-radius-active;
			}
		}

		&-bottom {
			&-left {
				border-bottom-left-radius: $rounded-box-border-radius-active;
			}

			&-right {
				border-bottom-right-radius: $rounded-box-border-radius-active;
			}
		}
	}
}