@use './../abstracts' as *;

$bg: #EBEFF6;
$py: .75rem;
$px: 1rem;

// focus
$bg-focus: $bg;
$border-color-focus: #9DAAC3;

// disabled
$disabled-color: rgba(map-get($main-colors, "secondary"), 0.5);

// placeholder
$placeholder-fw: $regular;
$placeholder-c: rgba(map-get($main-colors, "secondary"), 0.5);

.form-dotted {
    background-color: $bg;
    border: 1px dashed transparent;

    font-weight: $bold;

    padding: $py $px;

    text-transform: uppercase;

    &:focus {
        background-color: $bg-focus;
        border-color: $border-color-focus;
    }

    &:disabled {
        color: $disabled-color;
        background-color: $bg;
    }

    &::placeholder {
        color: $placeholder-c;
        font-weight: $placeholder-fw;

        text-transform: none;
    }
}