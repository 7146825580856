@use './../abstracts' as *;
@use './shadow-box' as *;

$shadow-wrapper-height: 2rem;

.box-cart {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;

    @include breakpoint-min-lg {
        // overflow: hidden;

        position: static;

        flex-direction: column-reverse;
        gap: 2rem;

        >* {
            @include shadow;
            border-radius: .375rem;
        }

        &.sticky-top-desktop {
            position: sticky;
            position: -webkit-sticky;

            inset: unset;
            top: 0;
        }
    }

    &:after {
        @include breakpoint-max-lg {
            content: "";

            position: absolute;
            top: -#{$shadow-wrapper-height};
            left: 0;

            pointer-events: none;

            width: 100%;
            height: $shadow-wrapper-height;

            background-image: linear-gradient(180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.12) 100%);
        }
    }

    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.25rem;

        position: relative;



        @include breakpoint-min-lg {
            align-items: start;
            flex-direction: column;
            gap: 1rem;
        }
    }
}