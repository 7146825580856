$main-colors: (
	"primary": #055cf5,
	"secondary": #26267d,
	"third": #e61e5c,
	"fourth": #d0dff8,
	"fifth": #ffbf00,

	// status colors
	"success": #6DC328,
	"warning": #fce160,
	"error": #E61E5C,
);

$default-colors: (
	"white": #fff,
	"light": #fcfaf8,
	"black": #000,
	"dark": #212429,
);