@use './../abstracts' as *;

.number-selector {
	display: inline-grid;
	grid-template-columns: repeat(3, 1.5rem);
	gap: 0.1875rem;
	align-items: center;

	.btn {
		padding: 0;

		&:not([disabled]) {
			font-variation-settings: "FILL" 1;
		}
	}

	.number-selector-items {
		color: map-get($main-colors, secondary);
		text-align: center;
		font-weight: $extra-bold;
		overflow: hidden;
	}
}