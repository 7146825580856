@use './../abstracts' as *;

$filter-size: 0.75rem;
$filter-position-y: -0.25rem;
$filter-position-x: -0.25rem;

// filter small
$filter-small-size: 0.625rem;
$filter-small-position-y: -0.125rem;
$filter-small-position-x: -0.25rem;

.filter {
	position: relative;

	&:after {
		content: "";

		width: $filter-size;
		height: $filter-size;

		position: absolute;
		top: $filter-position-y;
		right: $filter-position-x;

		border-radius: 50%;
		background-color: map-get($main-colors, third);
		box-shadow: 0 0 1px 0 rgba(38, 38, 125, 0.4);

		opacity: 0;

		transition: opacity 0.2s ease-in-out;
	}

	&.filter-small {
		&::after {
			width: $filter-small-size;
			height: $filter-small-size;

			top: $filter-small-position-y;
			right: $filter-small-position-x;
		}
	}

	&.filter-active {
		&:after {
			opacity: 1;
		}
	}
}