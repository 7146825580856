@use './../abstracts' as *;

// accordion
$accordion-items-space-between: 0.5rem;
$accordion-color: map-get($main-colors, secondary);

// accordion item
$accordion-item-border-radius: 0.5rem;
$accordion-item-border-color: rgba(map-get($main-colors, primary), 26%);
$accordion-item-active-border-color: map-get($main-colors, primary);

// accordion header
$accordion-header-border-width: 0;
$accordion-header-padding-y: 1.125rem;
$accordion-header-padding-x: 1rem;

// accordio button
$accordion-button-gap: 1rem;

.accordion {
	&.accordion-space {
		display: flex;
		flex-direction: column;
		gap: $accordion-items-space-between;

		.accordion {
			&-item {
				color: $accordion-color;
				border-radius: $accordion-item-border-radius;
				transition: border-color 0.2s ease-in-out;

				&,
				&:not(:first-of-type),
				&:last-of-type {
					border: 1px solid $accordion-item-border-color;

					.accordion-button.collapsed {
						border-radius: unset;
					}
				}

				&.active {
					border-color: $accordion-item-active-border-color;
				}
			}

			&-header {
				border: $accordion-header-border-width;
			}

			&-button {
				color: $accordion-color;
				background-color: unset;
				border-radius: unset;
				gap: $accordion-button-gap;

				border-top: {
					left-radius: $accordion-item-border-radius;
					right-radius: $accordion-item-border-radius;
				}

				&:not(.collapsed) {
					box-shadow: unset;
				}

				&:focus {
					border: unset;
					box-shadow: unset;
				}
			}
		}
	}
}