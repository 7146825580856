@use './../abstracts' as *;

$form-control-color: map-get($main-colors, secondary);
$form-control-border-width: 0.0625rem;
$form-control-border-color: #9daac3;

// input focus
$form-control-focus-color: map-get($main-colors, secondary);
$form-control-focus-box-shadow: unset;
$form-control-focus-border-color: map-get($main-colors, primary);

// input disabled
$form-control-disabled-color: rgba(map-get($main-colors, secondary), 0.7);
$form-control-disabled-bg-color: #f1f1f1;

// textarea
$form-control-textarea-min-height: 6.75rem;

.form {
	&-control {
		color: $form-control-color;
		border: $form-control-border-width solid $form-control-border-color;

		transition: all .15s ease-in-out;

		&:focus {
			color: $form-control-focus-color;
			box-shadow: $form-control-focus-box-shadow;
			border-color: $form-control-focus-border-color;
		}

		&:disabled {
			color: $form-control-disabled-color;
			background-color: $form-control-disabled-bg-color;
		}
	}
}

textarea {
	&.form-control {
		min-height: $form-control-textarea-min-height;
	}
}