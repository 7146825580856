$line-separator-height: 0.0625rem;

.line-separator {
	display: grid;
	grid-template-columns: 0.625rem auto 0.625rem;
	place-items: center;
	justify-content: center;
	gap: 0.25rem;

	text-align: center;

	&::before,
	&:after {
		content: "";

		width: 100%;
		height: $line-separator-height;
		background-color: currentColor;
	}
}
