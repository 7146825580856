$footer-color: #{var(--white)};
$footer-bg-color: #{var(--secondary)};

$footer-padding-top: 1.75rem;
$footer-padding-bottom: 0.75rem;

footer {
	&:not(.footer) {
		padding-bottom: 1.875rem;
	}

	&.footer {
		color: $footer-color;
		background-color: $footer-bg-color;

		padding: {
			top: $footer-padding-top;
			bottom: $footer-padding-bottom;
		}
	}
}
