body {
    &.page-full {
        display: flex;
        flex-direction: column;

        main {
            display: flex;
            flex-direction: column;
            flex: 1;

            >.page-full-child {
                flex: 1;
            }
        }
    }

    &.header-open {
        overflow-y: hidden;
    }
}

// ! test
body {
    display: flex;
    flex-direction: column;

    main {
        // display: flex;
        // flex-direction: column;
        flex: 1;

        /* >.page-full-child {
            flex: 1;
        } */
    }
}