@use './../abstracts' as *;

$input-search-street-bg-color: var(--white);
$input-search-street-box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.14);

$input-search-street-padding: 0.75rem 3rem 0.75rem 3.5rem;
$input-search-street-border-radius: 0.375rem 1.5rem 1.5rem 0.375rem;

.form {
	&-search-street {
		position: relative;

		.form-control {
			border: unset;
			background-color: $input-search-street-bg-color;
			padding: $input-search-street-padding;
			border-radius: $input-search-street-border-radius;
			box-shadow: $input-search-street-box-shadow;
		}

		.icon:nth-last-child(1) {
			left: unset;
			right: 0;
		}
	}
}