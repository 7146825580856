@use './../abstracts' as *;

$picture-frame-bg-color: #{var(--white)};
$picture-frame-padding: 0.3125rem;
$picture-frame-border-width: 0.0625rem;
$picture-frame-border-color: map-get($main-colors, secondary);
$picture-frame-border-radius: 0.5rem;
$picture-frame-aspect-ratio: 1;
$picture-frame-max-size: 6.125rem;

.picture {
	&-frame {
		&-grid {
			display: grid;
			gap: 1rem;
			grid-template-columns: repeat(3, 1fr);
			justify-items: center;

			@include breakpoint-min-md {
				grid-template-columns: repeat(6, 1fr);
			}
		}

		display: grid;
		place-items: center;
		text-align: center;

		background-color: $picture-frame-bg-color;
		padding: $picture-frame-padding;
		border: $picture-frame-border-width solid $picture-frame-border-color;
		border-radius: $picture-frame-border-radius;
		aspect-ratio: $picture-frame-aspect-ratio;

		max-width: $picture-frame-max-size;
		max-height: $picture-frame-max-size;

		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}
}