@use './../abstracts' as *;

$header-hp-scrolled-border-bottom: 1px solid transparent;

$header-hp-scrolled-border-color: rgba(map-get($default-colors, light), 0.2);
$header-hp-scrolled-background-color: map-get($main-colors, primary);

.header-hp {
	padding-block: 2rem;

	transition: all 0.2s ease-in-out;
	border-bottom: $header-hp-scrolled-border-bottom;

	&.scrolled {
		border-color: $header-hp-scrolled-border-color;
		background-color: $header-hp-scrolled-background-color;
		padding-block: 1rem;

		.logo-header-hp {
			width: 129px;
		}
	}
}

$header-padding-y: 1rem;

$header-lg-padding-y: 1.5rem;
$header-lg-bg-color: rgba(map-get($default-colors, "white"), 80%);

.header {
	padding-block: $header-padding-y;

	&-brand {
		@include breakpoint-min-lg {
			width: 200px;
		}

	}

	@include breakpoint-min-lg {
		padding-block: $header-lg-padding-y;
		background-color: $header-lg-bg-color;
		box-shadow: 0 2px 6px 0 rgba(38, 38, 125, 0.50);
	}

	&-container {
		display: flex;
		justify-content: space-between;
		gap: 1rem;
	}

	.hamburger {
		@include breakpoint-min-lg {
			display: none;
		}
	}
}