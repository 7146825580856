@use './../abstracts' as *;

$logo-footer-width: 8.0625rem;
$logo-footer-md-width: 16.125rem;

.logo {
	&-header-hp {
		width: 11.25rem;
		transition: all 0.1s ease-in-out;
	}

	&-footer {
		width: $logo-footer-width;

		@include breakpoint-min-md {
			width: $logo-footer-md-width;
		}
	}
}