@use './../abstracts' as *;

$site-text-color: #{var(--secondary)};
$site-bg-color: map-get($default-colors, light);

html,
body {
	overflow-x: hidden;
	position: relative;
}

body {
	font-family: $site-font-family;
	color: $site-text-color;
	background-color: $site-bg-color;
	min-height: 100vh;
}

textarea {
	resize: none;

	&::placeholder {
		font-style: italic;
		font-weight: $light;
		color: #00000040;
	}
}

p:last-of-type {
	margin-bottom: 0;
}

hr {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: #b4c0c5;
}

b,
strong {
	font-weight: $bold;
}

video {
	max-width: 100%;
	height: auto;
	display: block;
}

small {
	font-size: 0.75rem;
	font-weight: $light;
}

a {
	color: var(--primary);
	font-weight: $semi-bold;
	text-decoration: none;

	&:hover {
		color: var(--primary-dark);
	}
}