@use './../abstracts' as *;

$badge-border-radius: 0.1875rem;
$badge-font-weight: $bold;

// badge regular
$badge-font-size: 0.875rem;

$badge-padding-top: 0.1875rem;
$badge-padding-bottom: 0.1875rem;
$badge-padding-left: 0.5rem;
$badge-padding-right: 0.5rem;

// badge sm
$badge-sm-font-size: 0.75rem;

$badge-sm-padding-top: 0.0625rem;
$badge-sm-padding-bottom: 0.125rem;
$badge-sm-padding-left: 0.25rem;
$badge-sm-padding-right: 0.25rem;

.badge {
	font-style: italic;
	border-radius: $badge-border-radius;
	line-height: 1rem;

	padding: {
		top: $badge-padding-top;
		right: $badge-padding-right;
		bottom: $badge-padding-bottom;
		left: $badge-padding-left;
	}

	&.badge-sm {
		font-size: $badge-sm-font-size;
		line-height: 0.875rem;

		padding: {
			top: $badge-sm-padding-top;
			right: $badge-sm-padding-right;
			bottom: $badge-sm-padding-bottom;
			left: $badge-sm-padding-left;
		}
	}
}