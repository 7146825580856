@use './../abstracts' as *;

// form check
// $form-check-min-height: 1.75rem;
$form-check-spacing: 0.5313rem;
$form-check-margin-bottom: 0;

// form check input
// $form-check-input-size: $form-check-min-height;
// $form-check-input-border-width: 0.0938rem;

$form-check-input-transition: true; // bool
$form-check-input-checked-bg-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMSA5Ij48cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0xLjUyIDMuNTJjMS4wMy45MSAyLjM5IDIuMTYgMi4zOSAyLjE2UzcuMiAxLjgyIDEwLjI3IDBsLjIzLjY4Yy0xLjE0LjY4LTQuNTUgNC41NS01LjggNy45NiAwIDAtMy41Mi0zLjQxLTQuMi00LjJsMS4wMi0uOTJaIi8+PC9zdmc+");
$form-check-input-checked-bg-size: 0.625rem;

// form check input - focus
$form-check-input-focus-box-shadow: unset;

// form check input - disabled
$form-check-input-disabled-border-color: #9daac3;

// form check label
$form-check-label-font-weight: $bold;
$form-check-label-font-size: 1rem;

// form check label - disabled
$form-check-label-disabled-opacity: 1;

// checkbox - check
$checkbox-input-check-size: 1.75rem;
$checkbox-input-check-border-width: 0.0938rem;
$checkbox-input-check-border-color: map-get($main-colors, primary);

$checkbox-input-check-focus-border-color: map-get($main-colors, primary);

// checkbox - radio
$checkbox-input-radio-bg-color: transparent;
$checkbox-input-radio-size: 1.5rem;
$checkbox-input-radio-border-width: 0.0625rem;
$checkbox-input-radio-border-color: map-get($main-colors, secondary);

$checkbox-input-radio-checked-dot-size: 0.75rem;
$checkbox-input-radio-checked-dot-bg-color: map-get($main-colors, primary);

.form-check {
	display: flex;
	align-items: center;
	gap: $form-check-spacing;

	// min-height: $form-check-min-height;
	padding-left: 0;
	margin-bottom: $form-check-margin-bottom;

	&-input {
		margin-top: 0;
		flex-shrink: 0;
		white-space: nowrap;

		@if ($form-check-input-transition) {
			transition: all 0.2s ease-in-out;
		}

		// type checkbox
		&[type="checkbox"] {
			width: $checkbox-input-check-size;
			height: $checkbox-input-check-size;

			border: {
				width: $checkbox-input-check-border-width;
				color: $checkbox-input-check-border-color;
			}

			&:focus {
				border-color: $checkbox-input-check-focus-border-color;
			}
		}

		// type radio
		&[type="radio"] {
			background-color: $checkbox-input-radio-bg-color;

			width: $checkbox-input-radio-size;
			height: $checkbox-input-radio-size;

			display: grid;
			place-content: center;

			border: {
				width: $checkbox-input-radio-border-width;
				color: $checkbox-input-radio-border-color;
			}

			&:before {
				content: "";
				width: $checkbox-input-radio-checked-dot-size;
				height: $checkbox-input-radio-checked-dot-size;
				border-radius: 50%;

				background-color: transparent;

				opacity: 0;
				transition: opacity 0.2s ease-in-out;
			}

			&:checked {
				background-color: transparent;
				background-image: unset;

				&:before {
					opacity: 1;
					background-color: $checkbox-input-radio-checked-dot-bg-color;
				}
			}
		}

		// focus
		&:focus {
			box-shadow: $form-check-input-focus-box-shadow;
		}

		// disabled
		&:disabled,
		&[disabled] {
			border-color: $form-check-input-disabled-border-color;

			~.form-check-label {
				opacity: $form-check-label-disabled-opacity;
			}
		}
	}

	.form-check-input {
		margin-left: 0;

		&[type="checkbox"]:checked {
			background-image: $form-check-input-checked-bg-image;
			background-size: $form-check-input-checked-bg-size;
		}
	}

	&-label {
		color: map-get($main-colors, secondary);
		white-space: nowrap;

		font: {
			size: $form-check-label-font-size;
			weight: $form-check-label-font-weight;
		}
	}
}