@use './../abstracts' as *;
@use "./form-floating" as *;

$input-group-font-size: 1.125rem;
$input-group-font-weight: $medium;

$input-group-recess: 0.25rem;

$input-group-form-control-padding-y: 0.8125rem;
$input-group-form-control-padding-x: 1.75rem;
$input-group-form-control-border-width: 0.125rem;
$input-group-form-control-border-color: map-get($main-colors, primary);

$input-group-form-control-placeholder-color: #b9b9b9;
$input-group-btn-right-border-radius-left: 0.375rem;
$input-group-btn-right-border-radius-right: 1.5rem;

$input-group-btn-left-border-radius-left: 1.5rem;
$input-group-btn-left-border-radius-right: 0.375rem;

.input-group {
	.form-control {
		border-width: $input-group-form-control-border-width;
		border-color: $input-group-form-control-border-color;

		padding: $input-group-form-control-padding-y {
			top: $input-group-form-control-padding-y;
			bottom: $input-group-form-control-padding-y;
			left: $input-group-form-control-padding-x;
			right: calc($input-group-form-control-padding-x + $input-group-recess);
		}

		line-height: 1;

		&::placeholder {
			color: $input-group-form-control-placeholder-color;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		font-size: $input-group-font-size;
		font-weight: $input-group-font-weight;

		z-index: 6;

		padding: {
			top: 0.8125rem;
			bottom: 0.8125rem;
		}
	}

	// btn left
	>.btn:first-child {
		border-top-right-radius: $input-group-btn-left-border-radius-right !important;
		border-bottom-right-radius: $input-group-btn-left-border-radius-right !important;

		border-top-left-radius: $input-group-btn-left-border-radius-left;
		border-bottom-left-radius: $input-group-btn-left-border-radius-left;

		margin-right: -#{$input-group-recess} !important;
	}

	// btn right
	>.btn:not(:first-child) {
		border-top-right-radius: $input-group-btn-right-border-radius-right;
		border-bottom-right-radius: $input-group-btn-right-border-radius-right;

		border-top-left-radius: $input-group-btn-right-border-radius-left !important;
		border-bottom-left-radius: $input-group-btn-right-border-radius-left !important;

		margin-left: -#{$input-group-recess} !important;
	}
}