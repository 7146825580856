@use './../abstracts' as *;

.fw {
	&-medium {
		font-weight: $medium !important;
	}

	&-extrabold {
		font-weight: $extra-bold !important;
	}
}