@use './../abstracts' as *;

.hamburger {
    padding: 0;

    &,
    &:hover,
    &.is-active:hover {
        opacity: 1;
    }

    &-box {
        width: 24px;
        height: 24px;

        display: grid;
        place-items: center;
    }

    &-inner {

        &,
        &:before,
        &:after {
            width: 16px;
            height: 2.5px;

            background-color: var(--primary);

            &:hover {
                background-color: var(--primary-dark);
            }
        }

        &:before {
            top: -6px;
        }


        &:after {
            bottom: -6px;
        }
    }

    &.is-active {

        .hamburger-inner,
        .hamburger-inner:before,
        .hamburger-inner:after {
            background-color: var(--primary-dark);
        }
    }
}