@use './../abstracts' as *;

// general
$title-color: map-get($main-colors, secondary);
$title-font-family: null;
$title-font-weight: $extra-bold;
$title-margin-bottom: unset;

// h1
$h1-color: $title-color;
$h1-font-family: $title-font-family;
$h1-font-size: 2rem;
$h1-font-weight: $title-font-weight;
$h1-line-height: null;
$h1-margin-bottom: $title-margin-bottom;

// h2
$h2-color: $title-color;
$h2-font-family: $title-font-family;
$h2-font-size: 1.5625rem;
$h2-font-weight: $title-font-weight;
$h2-line-height: null;
$h2-margin-bottom: $title-margin-bottom;

// h3
$h3-color: $title-color;
$h3-font-family: $title-font-family;
$h3-font-size: 1.4375rem;
$h3-font-weight: $title-font-weight;
$h3-line-height: null;
$h3-margin-bottom: $title-margin-bottom;

// h4
$h4-color: $title-color;
$h4-font-family: $title-font-family;
$h4-font-size: 1.25rem;
$h4-font-weight: $title-font-weight;
$h4-line-height: null;
$h4-margin-bottom: $title-margin-bottom;

// h5
$h5-color: $title-color;
$h5-font-family: $title-font-family;
$h5-font-size: 1.1875rem;
$h5-font-weight: $title-font-weight;
$h5-line-height: null;
$h5-margin-bottom: $title-margin-bottom;

// h6
$h6-color: $title-color;
$h6-font-family: $title-font-family;
$h6-font-size: 0.875rem;
$h6-font-weight: $title-font-weight;
$h6-line-height: null;
$h6-margin-bottom: $title-margin-bottom;

.h1,
h1 {
	color: $h1-color;

	font: {
		family: $h1-font-family;
		size: $h1-font-size;
		weight: $h1-font-weight;
	}

	line-height: $h1-line-height;
	margin-bottom: $h1-margin-bottom;
}

.h2,
h2 {
	color: $h2-color;

	font: {
		family: $h2-font-family;
		size: $h2-font-size;
		weight: $h2-font-weight;
	}

	line-height: $h2-line-height;
	margin-bottom: $h2-margin-bottom;
}

.h3,
h3 {
	color: $h3-color;

	font: {
		family: $h3-font-family;
		size: $h3-font-size;
		weight: $h3-font-weight;
	}

	line-height: $h3-line-height;
	margin-bottom: $h3-margin-bottom;
}

.h4,
h4 {
	color: $h4-color;

	font: {
		family: $h4-font-family;
		size: $h4-font-size;
		weight: $h4-font-weight;
	}

	line-height: $h4-line-height;
	margin-bottom: $h4-margin-bottom;
}

.h5,
h5 {
	color: $h5-color;

	font: {
		family: $h5-font-family;
		size: $h5-font-size;
		weight: $h5-font-weight;
	}

	line-height: $h5-line-height;
	margin-bottom: $h5-margin-bottom;
}

.h6,
h6 {
	color: $h6-color;

	font: {
		family: $h6-font-family;
		size: $h6-font-size;
		weight: $h6-font-weight;
	}

	line-height: $h6-line-height;
	margin-bottom: $h6-margin-bottom;
}

// for homepage
.hp-display-1 {
	font-weight: $regular;
	line-height: normal !important;

	@include breakpoint-min-xl {
		font-size: 3.25rem !important;
	}
}

.hp-display-2 {
	font-weight: $regular;
	line-height: normal !important;

	@include breakpoint-min-lg {
		font-size: 2.75rem !important;
	}
}

.hp-display-3 {
	font-weight: $regular;
	line-height: normal !important;

	@include breakpoint-min-lg {
		font-size: 1.875rem !important;
	}
}

.hp-display-4 {
	font-weight: $regular;
	line-height: normal !important;

	@include breakpoint-min-xl {
		font-size: 1.375rem !important;
	}
}

.hp-display-5 {
	font-weight: $regular;
	line-height: normal !important;

	@include breakpoint-min-xl {
		font-size: 1.125rem !important;
	}
}