.slider-opacity {
	.swiper-slide {
		transition: opacity 0.4s ease-in-out;
		opacity: 0;

		&.swiper-slide-active {
			opacity: 1;
		}
	}
}

.arrow {
	&-prev,
	&-next {
		transition: opacity 0.2s ease-in-out;

		&.arrow-disabled {
			opacity: 0.16;
		}
	}
}
