@use './../abstracts' as *;

$has-text-stroke-class: true;

// generate text color
@each $color,
$value in $main-colors {
	.text-#{$color} {
		color: var(--#{$color}) !important;
		opacity: var(--text-opacity);
	}
}

@if $has-text-stroke-class {

	@each $color,
	$value in $main-colors {

		// text stroke
		.text-stroke-#{$color} {
			--stroke-width: 1px;
			color: transparent !important;

			-webkit-text-stroke: var(--stroke-width) var(--#{$color}) !important;
		}
	}
}