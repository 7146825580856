.canvas {
	position: absolute;

	width: 100%;
	height: 100%;

	top: 0;
	left: 0;

	z-index: -1;
	pointer-events: none;

	.canvas-item {
		position: absolute;

		--canvas-top: unset;
		--canvas-left: unset;
		--canvas-right: unset;
		--canvas-bottom: unset;
		--canvas-width: unset;
		--canvas-height: unset;

		--canvas-rotate: unset;

		top: var(--canvas-top);
		bottom: var(--canvas-bottom);
		left: var(--canvas-left);
		right: var(--canvas-right);

		width: var(--canvas-width);
		height: var(--canvas-height);
	}

	&.canvas-bg {
		&.filter {
			&:after {
				content: "";

				position: absolute;

				width: 100%;
				height: 100%;

				top: 0;
				left: 0;

				mix-blend-mode: multiply;
			}

			img {
				filter: grayscale(1);
			}
		}

		img {
			width: 100%;
			height: 100%;

			object-fit: cover;
			object-position: center;
		}
	}
}
