@use './../abstracts' as *;

$btn-icon-hover-rotate: false;
$btn-font-weight: $extra-bold;

.btn {
	// display: inline-flex;
	// align-items: center;
	// gap: 1rem;

	--bs-btn-font-family: "Karla", sans-serif;
	--bs-btn-font-size: 1rem;

	--bs-btn-color: inherit;

	--bs-btn-border-radius: 0.375rem;
	--bs-btn-box-shadow: unset;
	--bs-btn-focus-box-shadow: unset;

	--bs-btn-padding-top: 1.0625rem;
	--bs-btn-padding-right: 1rem;
	--bs-btn-padding-bottom: 1.0625rem;
	--bs-btn-padding-left: 1rem;

	--bs-btn-border-width: 0;

	--bs-btn-font-weight: #{$btn-font-weight};

	--bs-btn-active-shadow: unset;

	--bs-btn-line-height: 1;

	padding: var(--bs-btn-padding-top) var(--bs-btn-padding-right) var(--bs-btn-padding-bottom) var(--bs-btn-padding-left);

	&:not(.btn-action) {

		>[class|="material"],
		>[class|="icon"] {
			margin-left: -0.4rem;
			margin-right: -0.4rem;
			line-height: 0;
		}
	}

	// hover rotate
	@if $btn-icon-hover-rotate {
		>* {
			transition: rotate 0.2s ease;
		}

		&:hover {
			>[class|="material"] {
				rotate: 180deg;
			}
		}
	}

	@each $color,
	$value in $main-colors {

		// btn
		&-#{$color} {

			// status: default
			@if (lightness($value) > 75) {
				--bs-btn-color: var(--dark);
			}

			@else {
				--bs-btn-color: var(--white);
			}

			--bs-btn-bg: var(--#{$color});
			--bs-btn-border-color: var(--#{$color});

			// status: hover
			@if (lightness($value) > 75) {
				--bs-btn-hover-color: var(--dark);
			}

			@else {
				--bs-btn-hover-color: var(--white);
			}

			--bs-btn-hover-bg: var(--#{$color}-dark);
			--bs-btn-hover-border-color: var(--#{$color}-dark);

			// status: active
			@if (lightness($value) > 75) {
				--bs-btn-active-color: var(--dark);
			}

			@else {
				--bs-btn-active-color: var(--white);
			}

			--bs-btn-active-bg: var(--#{$color}-darken);
			--bs-btn-active-border-color: var(--#{$color}-darken);

			// disabled
			@if (lightness($value) > 75) {
				--bs-btn-disabled-color: var(--dark);
			}

			@else {
				--bs-btn-disabled-color: var(--white);
			}

			--bs-btn-disabled-bg: var(--#{$color});
			--bs-btn-disabled-border-color: var(--#{$color});
			--bs-btn-disabled-opacity: 0.45;
		}

		// outline btn
		&-outline-#{$color} {
			// status: default
			--bs-btn-color: var(--#{$color});
			--bs-btn-bg: var(--white);
			--bs-btn-border-color: var(--#{$color});

			--bs-btn-border-width: 2px;

			// status: hover
			--bs-btn-hover-color: var(--white);
			--bs-btn-hover-bg: var(--#{$color});
			--bs-btn-hover-border-color: var(--#{$color});

			// status: active
			--bs-btn-active-color: var(--white);
			--bs-btn-active-bg: var(--#{$color}-darken);
			--bs-btn-active-border-color: var(--#{$color}-darken);
		}
	}

	&-gradient {
		position: relative;

		&:before,
		&:after {
			content: "";

			position: absolute;
			top: 0;
			left: 0;

			z-index: -1;

			width: 100%;
			height: 100%;

			transition: opacity 0.2s ease-in-out;

			border-radius: inherit;
		}

		&:before {
			background-color: var(--primary);
		}

		&:after {
			background: linear-gradient(134deg,
				#{map-get($main-colors, primary)} 0%,
				#1a1a85 100%);
		}

		// status: default
		--bs-btn-color: var(--white);
		background: unset !important;

		// status: hover
		--bs-btn-hover-color: var(--white);

		&:hover {
			&::after {
				opacity: 0;
			}
		}

		// status: active
		--bs-btn-active-color: var(--white);

		&:active {
			&::before {
				background-color: var(--primary-darken);
			}
		}
	}

	&-link {
		text-decoration: underline;
		text-decoration-thickness: 0.0625rem;
		letter-spacing: -0.0313rem;
		text-underline-offset: 0.125rem;

		--bs-btn-color: inherit;

		--bs-btn-bg: transparent;
		--bs-btn-hover-bg: transparent;
		--bs-btn-active-bg: transparent;

		--bs-btn-padding-top: 0;
		--bs-btn-padding-bottom: 0;
		--bs-btn-padding-left: 0;
		--bs-btn-padding-right: 0;

		--bs-btn-font-weight: #{$bold};

		@each $color,
		$value in $main-colors {
			&.btn-#{$color} {
				--bs-btn-color: var(--#{$color});
				--bs-btn-hover-color: var(--#{$color}-darken);
				--bs-btn-active-color: var(--#{$color}-darken);
			}
		}
	}

	// btn login
	&-login {
		$btn-padding-x: 3.5rem;

		position: relative;

		// status: default
		--bs-btn-color: var(--primary);
		--bs-btn-bg: var(--white);
		--bs-btn-border-color: var(--primary);

		--bs-btn-border-width: 1px;

		--bs-btn-padding-top: 0.8125rem;
		--bs-btn-padding-bottom: 0.8219rem;
		--bs-btn-padding-left: #{$btn-padding-x};
		--bs-btn-padding-right: #{$btn-padding-x};

		--bs-btn-hover-bg: var(--primary);
		--bs-btn-hover-color: var(--white);

		--bs-btn-active-bg: var(--primary-darken);
		--bs-btn-active-color: var(--white);

		.login-icon {
			position: absolute;
			left: 1rem;
			top: 50%;
			transform: translateY(-50%);

			width: 1.5rem;
			height: 1.5rem;
		}
	}

	// btn action
	&-action {
		--bs-btn-border-radius: 9999px;
		--bs-btn-disabled-opacity: 0.34;
		display: inline-grid;
		place-items: center;

		padding: 0.5rem;
	}

	// btn default
	&-default {
		--bs-btn-padding-top: 0;
		--bs-btn-padding-bottom: 0;
		--bs-btn-padding-left: 0;
		--bs-btn-padding-right: 0;

		--bs-btn-font-weight: #{$extra-bold};

		@each $color,
		$value in $main-colors {
			&.btn-#{$color} {
				--bs-btn-color: var(--#{$color});
				--bs-btn-hover-color: var(--#{$color}-darken);
				--bs-btn-active-color: var(--#{$color}-darken);
				--bs-btn-bg: transparent;
				--bs-btn-hover-bg: transparent;
				--bs-btn-active-bg: transparent;
			}
		}
	}

	&-icon {
		display: inline-grid;
		place-items: center;

		width: 1.5rem;
		height: 1.5rem;

		aspect-ratio: 1;

		--bs-font-size: 1.25rem;

		--bs-btn-padding-top: .25rem;
		--bs-btn-padding-bottom: .25rem;
		--bs-btn-padding-left: .25rem;
		--bs-btn-padding-right: .25rem;

		>* {
			font-size: var(--bs-font-size) !important;
		}

		@each $color,
		$value in $main-colors {
			&.btn-#{$color} {
				--bs-btn-color: var(--#{$color});
				--bs-btn-hover-color: var(--#{$color}-darken);
				--bs-btn-active-color: var(--#{$color}-darken);

				--bs-btn-bg: transparent;
				--bs-btn-hover-bg: transparent;
				--bs-btn-active-bg: transparent;
			}
		}
	}

	// btn small
	&-sm {
		--bs-btn-font-size: 0.875rem;

		--bs-btn-font-weight: #{$bold};

		--bs-btn-padding-top: 0.25rem;
		--bs-btn-padding-bottom: 0.3125rem;
		--bs-btn-padding-left: 0.5625rem;
		--bs-btn-padding-right: 0.5625rem;

		--bs-btn-line-height: 1.0625rem;
	}

	&-md {
		--bs-btn-padding-top: 0.8438rem;
		--bs-btn-padding-bottom: 0.8438rem;
		--bs-btn-padding-left: 1rem;
		--bs-btn-padding-right: 1rem;
	}

	&-full {
		width: 100%;
	}

	&[aria-expanded] {
		transition: transform 0.2s ease-in-out;
	}

	&[aria-expanded="false"] {
		transform: rotate(180deg);
	}
}