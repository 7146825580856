$price-discounted-font-size: 1rem;

$price-discounted-width-abundance: 0.5rem;

.price {
	display: flex;
	align-items: center;
	gap: 0.5625rem;

	.discounted {
		position: relative;
		font-size: $price-discounted-font-size;

		&::after {
			content: "";

			background-color: currentColor;

			position: absolute;
			left: -#{calc($price-discounted-width-abundance / 2)};
			top: 50%;

			width: calc(100% + #{$price-discounted-width-abundance});
			height: 0.125rem;
			border-radius: 9999px;

			rotate: 10deg;
		}
	}
}
