$plenty-mx: -0.125rem;

hr {
	&.plenty {
		margin: {
			left: $plenty-mx;
			right: $plenty-mx;
		}
	}
}
