@use './../abstracts' as *;

// margin
.m-custom {
	margin: var(--p-space);
}

.my-custom {
	margin-top: var(--my-space);
	margin-bottom: var(--my-space);
}

.mt-custom {
	margin-top: var(--mt-space);
}

.mb-custom {
	margin-bottom: var(--mb-space);
}

.mx-custom {
	margin-left: var(--mx-space);
	margin-right: var(--mx-space);
}

.ms-custom {
	margin-left: var(--ms-space);
}

.me-custom {
	margin-right: var(--me-space);
}

// padding
.p-custom {
	padding: var(--p-space);
}

.py-custom {
	padding-top: var(--py-space);
	padding-bottom: var(--py-space);
}

.pt-custom {
	padding-top: var(--pt-space);
}

.pb-custom {
	padding-bottom: var(--pb-space);
}

.px-custom {
	padding-left: var(--px-space);
	padding-right: var(--px-space);
}

.ps-custom {
	padding-left: var(--ps-space);
}

.pe-custom {
	padding-right: var(--pe-space);
}

// gap
.gap-custom {
	gap: var(--g-space);
}

@each $media,
$value in $media-queries {
	@media (min-width: $value) {

		// margin
		.m-custom {
			margin: #{var(--m-#{$media}-space)
		}

		;
	}

	.my-#{$media}-x-custom {
		margin-top: #{var(--my-#{$media}-space)
	}

	;
	margin-bottom: #{var(--my-#{$media}-space)
}

;
}

.mt-#{$media}-custom {
	margin-top: var(--mt-#{$media}-space);
}

.mb-#{$media}-custom {
	margin-bottom: var(--mb-#{$media}-space);
}

.mx-#{$media}-custom {
	margin-left: var(--mx-#{$media}-space);
	margin-right: var(--mx-#{$media}-space);
}

.ms-#{$media}-custom {
	margin-left: var(--ms-#{$media}-space);
}

.me-#{$media}-custom {
	margin-right: var(--mr-#{$media}-space);
}

// padding
.py-#{$media}-custom {
	padding-top: var(--py-#{$media}-space);
	padding-bottom: var(--py-#{$media}-space);
}

.pt-#{$media}-custom {
	padding-top: var(--pt-#{$media}-space);
}

.pb-#{$media}-custom {
	padding-bottom: var(--pb-#{$media}-space);
}

.px-#{$media}-custom {
	padding-left: var(--px-#{$media}-space);
	padding-right: var(--px-#{$media}-space);
}

.ps-#{$media}-custom {
	padding-left: var(--ps-#{$media}-space);
}

.pe-#{$media}-custom {
	padding-right: var(--pe-#{$media}-space);
}

// gap
.gap-#{$media}-custom {
	gap: var(--gap-#{$media}-space);
}
}
}