@use './../abstracts' as *;

$custom-box-wrapper-bg-color: map-get($main-colors, fourth);
$custom-box-wrapper-padding-y: 0.625rem;
$custom-box-wrapper-padding-x: 0.625rem;

$custom-box-padding-y: 0.5rem;
$custom-box-padding-x: 0.8125rem;
$custom-box-bg-color: rgba(map-get($main-colors, primary), 0.15);
$custom-box-border-radius: 1.4375rem;

$custom-box-lg-bg-color: map-get($default-colors, "white");

$custom-box-active-border-color: map-get($main-colors, primary);

.custom-box {
	padding: $custom-box-padding-y $custom-box-padding-x;
	background-color: $custom-box-bg-color;
	border: 2px solid transparent;
	border-radius: $custom-box-border-radius;

	position: relative;

	transition: border-color 0.2s ease-in-out;



	&.active {
		border-color: $custom-box-active-border-color;
	}

	&-collapse {
		transition: transform 0.2s ease-in-out;

		&[aria-expanded="true"] {
			transform: rotate(180deg);
		}
	}

	&-wrapper {
		background-color: $custom-box-wrapper-bg-color;
		padding: $custom-box-wrapper-padding-y $custom-box-wrapper-padding-x;

		@include breakpoint-min-lg {
			background-color: $custom-box-lg-bg-color;
		}
	}
}