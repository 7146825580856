@use "./../abstracts" as *;

$navbar-action-gap: 2.5rem;

.navbar {
    @include breakpoint-max-lg {
        box-shadow: unset !important;
    }

    &-action {
        display: flex;
        align-items: center;
        gap: 1rem;

        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }
}