@use './../abstracts' as *;

// generate background colors
@each $color,
$value in $main-colors {
	.bg-#{$color} {
		background-color: var(--#{$color}) !important;
	}
}

.bg-contain {
	background-size: contain !important;
}

// gradient
.bg-gradient-primary {
	background-image: linear-gradient(102deg,
			map-get($main-colors, primary) 0%,
			#1a1a85 100%);
}