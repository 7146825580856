@use './../abstracts' as *;

// general
$text-font-family: null;

// p
$paragraph-font-family: $text-font-family;
$paragraph-font-size: null;
$paragraph-font-weight: null;
$paragraph-line-height: 1.1875;

// .lead
$lead-exist: true;
$lead-font-family: $text-font-family;
$lead-font-size: 1.375rem;
$lead-font-weight: $bold;
$lead-line-height: 1.625rem;

// small
$small-font-family: $text-font-family;
$small-font-size: 0.75rem;
$small-font-weight: null;
$small-line-height: 0.875rem;

p {
	font: {
		family: $paragraph-font-family;
		size: $paragraph-font-size;
		weight: $paragraph-font-weight;
	}

	line-height: $paragraph-line-height;
}

small {
	font: {
		family: $small-font-family;
		size: $small-font-size;
		weight: $small-font-weight;
	}

	line-height: $small-line-height;
}

@if ($lead-exist) {
	.lead {
		font: {
			family: $lead-font-family;
			size: $lead-font-size;
			weight: $lead-font-weight;
		}

		line-height: $lead-line-height;
	}
}