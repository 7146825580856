$brand-image-width: 2.8125rem;
$brand-image-height: 1.75rem;

.brand-image {
	width: $brand-image-width;
	height: $brand-image-height;

	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		height: auto;
	}
}
