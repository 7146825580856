$shadow-wrapper-height: 2rem;

.shadow-wrapper {
	position: relative;

	&:after {
		content: "";

		position: absolute;
		top: -#{$shadow-wrapper-height};
		left: 0;

		pointer-events: none;

		width: 100%;
		height: $shadow-wrapper-height;

		background-image: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.12) 100%
		);
	}
}
