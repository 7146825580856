@use './../abstracts' as *;

// shadow box
$shadow-box-bg-color: var(--white);

$shadow-box-border-radius: 0.375rem;
$shadow-box-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);

$shadow-box-padding-y: 0.875rem;
$shadow-box-padding-x: 0.625rem;

// shadow box small
$shadow-box-sm-padding-y: 1rem;
$shadow-box-sm-padding-x: 0.5625rem;

// shadow box lg
$shadow-box-lg-padding-y: 1.5rem;
$shadow-box-lg-padding-x: 1.5rem;

@mixin shadow {
	box-shadow: $shadow-box-box-shadow;
}

.shadow-box {
	--shadow-box-py: #{$shadow-box-padding-y};
	--shadow-box-px: #{$shadow-box-padding-x};

	background-color: $shadow-box-bg-color;
	padding: var(--shadow-box-py) var(--shadow-box-px);
	border-radius: $shadow-box-border-radius;

	overflow: hidden;

	@include shadow;

	@include breakpoint-min-lg {
		--shadow-box-py: #{$shadow-box-lg-padding-y};
		--shadow-box-px: #{$shadow-box-lg-padding-x};
	}

	&.box-sm {
		padding: $shadow-box-sm-padding-y $shadow-box-sm-padding-x;
	}
}