@use './../abstracts' as *;

body.main-center {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-height: 100vh;

	header {
		margin-bottom: 0 !important;
	}

	main {
		:first-child {
			margin-top: 0 !important;
		}

		:last-child {
			margin-top: 0 !important;
		}

		margin: {
			top: auto;
			bottom: auto;
		}
	}
}

main.flex-bottom-sm {
	@include breakpoint-max-lg {
		display: flex;
		flex-direction: column;

		.flex-bottom {
			&-container {
				height: 100%;

				// display: flex;
				flex-direction: column !important;
			}

			&-item {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				flex: 1;
			}
		}
	}
}