@use './../abstracts' as *;

// Category Select
$category-select-gap: 1.4375rem;
$category-select-border-radius: 0.2775rem;
$category-select-item-lg-gap: 1.5rem;

// Category Select Wrapper
$category-select-wrapper-bg-color: map-get($default-colors, light);

// Category Select Placeholder
$category-select-placeholder-box-shadow: 0 1px 4px 0 rgba(map-get($main-colors, secondary), 0.2);

// Category Select Item
$category-select-item-gap: 0.5625rem;
$category-select-item-lg-gap: 0.5625rem;
$category-select-item-lg-bg-color: map-get($default-colors, "white");
$category-select-item-lg-border-radius: .25rem;
$category-select-item-lg-width: 150px;
$category-select-item-lg-height: 72px;
$category-select-item-lg-border-size: 2px;

// Category Select Input
$category-select-input-checked-border-color: map-get($main-colors, primary);

// Category Select Label
$category-select-label-color: map-get($main-colors, secondary);
$category-select-label-font-size: 0.875rem;

// Category Select Item checked
$category-select-item-checked-border-color: map-get($main-colors, primary);
$category-select-item-checked-border-radius: .5rem;

// Category Select Item checked - Label
$category-select-item-checked-label-font-weight: $bold;

.category-select {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: $category-select-gap;

	@include breakpoint-min-lg {
		gap: $category-select-item-lg-gap;
	}

	&-wrapper {
		background-color: $category-select-wrapper-bg-color;

		position: sticky;
		top: 0;
		z-index: 1;

		/* &:after {
			content: "";

			position: absolute;
			bottom: -1rem;
			left: 0;
			right: 0;

			width: 100%;
			height: 1rem;

			background: linear-gradient(180deg,
					rgba(map-get($default-colors, light), 1) 0%,
					rgba(map-get($default-colors, light), 0) 100%);
		} */
	}

	&-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: $category-select-item-gap;
		z-index: 0;

		position: relative;

		@include breakpoint-min-md {
			flex-direction: row;
			justify-content: center;

			width: $category-select-item-lg-width;
			height: $category-select-item-lg-height;

			background-color: $category-select-item-lg-bg-color;
			border-radius: $category-select-item-lg-border-radius;

			border: $category-select-item-lg-border-size solid transparent;

			gap: 1rem;

			transition: border-color 0.2s ease-in-out;

			box-shadow: 0 1px 4px 0 rgba(38, 38, 125, 0.20);

			&:before {
				content: '';

				position: absolute;
				left: -#{$category-select-item-lg-border-size};
				top: -#{$category-select-item-lg-border-size};

				border-top-left-radius: $category-select-item-checked-border-radius;

				width: 26px;
				height: 26px;

				background: linear-gradient(135deg, rgba(5, 92, 245, 1) 0%, rgba(26, 26, 133, 1) 100%);
				clip-path: polygon(0 0, 0% 100%, 100% 0);

				opacity: 0;

				transition: opacity 0.2s ease-in-out;
			}
		}

		&.checked {
			@include breakpoint-min-md {
				border-color: $category-select-item-checked-border-color;
				border-radius: $category-select-item-checked-border-radius;

				box-shadow: 0 2px 8px 0 rgba(38, 38, 125, 0.50);

				&:before {
					opacity: 1;
				}
			}

			.category-select-label {
				font-weight: $category-select-item-checked-label-font-weight;
			}
		}
	}

	&-placeholder {
		position: relative;
		box-shadow: $category-select-placeholder-box-shadow;
		// overflow: hidden;

		border-radius: $category-select-border-radius;

		cursor: pointer;

		@include breakpoint-min-md {
			position: unset;
			box-shadow: unset;
		}
	}

	&-input {
		position: absolute;
		inset: 0;

		width: 100%;
		height: 100%;

		opacity: 0;

		&:checked {
			&~ {
				.category-select-image {
					border-color: $category-select-input-checked-border-color;
				}
			}
		}
	}

	&-image {
		position: relative;

		width: 3.3125rem;
		height: 3rem;

		padding: 0.1875rem;
		border: 0.125rem solid transparent;
		border-radius: $category-select-border-radius;

		display: flex;
		align-items: center;
		justify-content: center;

		transition: border-color 0.2s ease-in-out;

		z-index: -1;

		@include breakpoint-min-md {
			padding: 0;
			border: 0;
			box-shadow: unset;

			width: auto;
		}
	}

	&-label {
		color: $category-select-label-color;
		font-size: $category-select-label-font-size;
	}
}