@use './../abstracts' as *;

$chips-padding-top: 0.5625rem;
$chips-padding-bottom: 0.5625rem;
$chips-padding-left: 0.6875rem;
$chips-padding-right: 0.8125rem;

$chips-font-size: 0.875rem;
$chips-font-weight: $bold;

.chips {
	&-grid {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 0.5rem;
	}

	display: inline-block;

	input {
		display: none;
	}

	label {
		display: inline-flex;

		text-decoration: none;
		border-radius: 9999px;

		border: 0.0938rem solid;
		line-height: 0.99999999;

		font-size: $chips-font-size;
		font-weight: $chips-font-weight;

		padding: $chips-padding-top $chips-padding-left $chips-padding-bottom $chips-padding-right;

		cursor: pointer;

		transition: all 0.2s ease-in-out;

		&:after {
			content: "\e900";

			font-family: "driink";

			width: 0;
			overflow: hidden;

			transition: width 0.2s ease-in-out;
			font-size: 1.5rem;

			display: inline-flex;
			align-items: center;

			line-height: 0;
		}
	}

	@each $color,
	$value in $main-colors {
		&.chips-#{$color} {
			label {
				color: $value;
				border-color: $value;
				background-color: var(--white);
			}

			input {
				&:checked {
					~label {
						color: var(--white);
						background-color: $value;
						padding-right: 0;

						&:after {
							width: 1.5rem;
						}
					}
				}
			}
		}
	}

	&.active {
		&::after {
			content: "";
		}
	}
}