@use './../abstracts' as *;

.hero {
	display: flex;
	flex-direction: column-reverse;

	grid-template-rows: auto auto;

	position: relative;

	@include breakpoint-min-sm() {
		grid-template: 1fr;
	}

	@include breakpoint-min-md() {
		display: grid;
		align-items: center;

		>* {
			grid-area: 1 / 1;
		}
	}

	&-body {
		position: relative;
		padding-block: 1rem;

		* {
			color: var(--white);
		}

		@include breakpoint-max-md() {
			background-color: var(--primary);
		}

		padding-block: 8rem;
		padding-block-end: 4rem;
	}

	&-bg {
		aspect-ratio: 16/9;

		@include breakpoint-min-xxl() {
			aspect-ratio: 16/6;
		}

		img {
			object-fit: cover;
			object-position: right center;

			width: 100%;
			height: 100%;

			padding: {
				left: 0;
				right: 0;
			}
		}

		position: relative;

		&::after {
			content: "";

			position: absolute;
			top: 0;
			left: 0;
			right: 0;

			width: 100%;
			height: 40%;

			background: linear-gradient(0deg,
					rgba(map-get($main-colors, primary), 0) 0%,
					rgba(map-get($main-colors, primary), 1) 100%);

			@include breakpoint-min-md() {
				height: 100%;
				background-image: linear-gradient(115deg,
						#055cf5 15%,
						rgba(5, 92, 245, 0) 71%);
			}
		}
	}

	&-canva {
		position: absolute;
		left: 0;
		right: 0;

		object-fit: cover;
		width: 100%;
		height: 100%;
		opacity: 0.3;

		@include breakpoint-min-md() {
			width: 50%;
		}

		@include breakpoint-min-xl() {
			width: 40%;
		}
	}
}