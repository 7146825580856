@use './../abstracts' as *;

// general
$form-floating-x-spacing: 1rem;
$form-floating-height: calc(3rem + 2px);

// input default
$form-floating-border-color: map-get($main-colors, primary);

// input label
$form-floating-label-opacity: 0.5;
$form-floating-label-color: map-get($main-colors, secondary);

// input focus
$form-floating-focus-label-color: map-get($main-colors, secondary);
$form-floating-focus-label-opacity: 0.7;
$form-floating-focus-font-weight: $bold;
$form-floating-focus-padding-top: 0.9375rem;
$form-floating-focus-padding-bottom: 0.9375rem;
$form-floating-focus-transform: scale(0.85) translateY(-0.9rem) translateX(0.15rem);

@mixin form-control-focus-reusable-style {
	color: $form-floating-focus-label-color;
	opacity: $form-floating-focus-label-opacity;
	font-weight: $form-floating-focus-font-weight;
	transform: $form-floating-focus-transform;
}

.form {
	&-floating {
		.form-control {
			padding: {
				left: $form-floating-x-spacing;
				right: $form-floating-x-spacing;
			}

			&::placeholder {
				opacity: 0;
			}

			&:disabled {
				~label {
					opacity: 0.7;
				}
			}
		}

		>label {
			opacity: $form-floating-label-opacity;
			color: $form-floating-label-color;

			padding: {
				left: $form-floating-x-spacing;
				right: $form-floating-x-spacing;
			}
		}

		// focus input
		>.form {
			&-control {

				&-plaintext:focus,
				&-plaintext:not(:placeholder-shown),
				&:focus,
				&:not(:placeholder-shown) {
					padding-top: $form-floating-focus-padding-top;
					padding-bottom: $form-floating-focus-padding-bottom;
				}

				&:not(:placeholder-shown)~label:after {
					background-color: transparent;
				}

				&-plaintext~label,
				&:focus~label,
				&:not(:placeholder-shown)~label {
					@include form-control-focus-reusable-style;
				}
			}

			&-select~label {
				@include form-control-focus-reusable-style;
			}
		}
	}
}