@use './../abstracts' as *;

$menu-space: 1rem;
$menu-lg-space: 2.5rem;
$menu-collapse-bg: map-get($default-colors, "white");

$menu-link-color: map-get($main-colors, "secondary");

.menu {
    display: flex;
    gap: $menu-space;

    @include breakpoint-min-lg {
        gap: $menu-lg-space;
    }

    &--right {
        margin-left: auto;
    }

    &-nav {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &.menu-collapse-lg {
        .menu-nav {
            display: flex;

            @include breakpoint-max-lg {
                flex-direction: column;
                justify-content: center;
                gap: $menu-space;
                align-items: center;

                width: 100vw;
                height: calc(100vh - 56px); // 100vh - header mobile height

                padding: 1.5rem;
                background-color: $menu-collapse-bg;

                overflow-y: auto;
                position: fixed;
                inset: 0;
                top: unset;

                z-index: 100;

                transform: translateX(-100%);
                transition: transform 0.2s ease-in-out;

                border-top: 1px solid rgba(map-get($main-colors, "secondary"), 0.6);

                body.header-open & {
                    transform: translateX(0);
                }

            }

            @include breakpoint-min-lg {
                align-items: center;
                gap: $menu-lg-space;
            }
        }
    }

    &-item {
        text-transform: uppercase;
    }

    &-link {
        color: $menu-link-color;
        font-size: 1rem;
    }
}