$icomoon-font-family: "driink" !default;
$icomoon-font-path: "./../fonts" !default;

$driink-logout: "\e90f";
$driink-close: "\e900";
$driink-box: "\e901";
$driink-account: "\e902";
$driink-block: "\e903";
$driink-hamburger: "\e904";
$driink-cart-add: "\e905";
$driink-cart: "\e906";
$driink-email: "\e907";
$driink-event: "\e908";
$driink-handshake: "\e909";
$driink-pin: "\e90a";
$driink-star: "\e90b";
$driink-wallet: "\e90c";
$driink-trash-circle: "\e90d";
$driink-filter: "\e90e";

@font-face {
	font-family: "#{$icomoon-font-family}";
	src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8fwr3b");
	src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8fwr3b#iefix") format("embedded-opentype"),
	url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8fwr3b") format("truetype"),
	url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8fwr3b") format("woff"),
	url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8fwr3b##{$icomoon-font-family}") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="driink-"],
[class*=" driink-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "#{$icomoon-font-family}" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.5rem;
}

.driink-logout {
	&:before {
		content: $driink-logout;
	}
}

.driink-close {
	&:before {
		content: $driink-close;
	}
}

.driink-box {
	&:before {
		content: $driink-box;
	}
}

.driink-account {
	&:before {
		content: $driink-account;
	}
}

.driink-block {
	&:before {
		content: $driink-block;
	}
}

.driink-hamburger {
	&:before {
		content: $driink-hamburger;
	}
}

.driink-cart-add {
	&:before {
		content: $driink-cart-add;
	}
}

.driink-cart {
	&:before {
		content: $driink-cart;
	}
}

.driink-email {
	&:before {
		content: $driink-email;
	}
}

.driink-event {
	&:before {
		content: $driink-event;
	}
}

.driink-handshake {
	&:before {
		content: $driink-handshake;
	}
}

.driink-pin {
	&:before {
		content: $driink-pin;
	}
}

.driink-star {
	&:before {
		content: $driink-star;
	}
}

.driink-wallet {
	&:before {
		content: $driink-wallet;
	}
}

.driink-trash-circle {
	&:before {
		content: $driink-trash-circle;
	}
}

.driink-filter {
	&:before {
		content: $driink-filter;
	}
}