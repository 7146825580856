@use './../abstracts' as *;

$avatar-size: 24px;
$avatar-color: map-get($default-colors, "white");
$avatar-bg-color: map-get($main-colors, "primary");
$avatar-fw: $regular;

.avatar {
	width: $avatar-size;
	height: $avatar-size;

	aspect-ratio: 1;
	border-radius: 9999px;

	object-fit: cover;
	object-position: center;

	display: grid;
	place-items: center;

	color: $avatar-color;
	background-color: $avatar-bg-color;

	font-weight: $avatar-fw;
}