// Increase Bootstrap opacity classes
// https://getbootstrap.com/docs/5.3/utilities/opacity/

$opacity: (
	"10": 0.1,
	"20": 0.2,
	"40": 0.4,
	"60": 0.6,
	"80": 0.8,
);

@each $item, $value in $opacity {
	.opacity-#{$item} {
		opacity: $value !important;
	}
}
