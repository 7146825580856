.product-placeholder {
	width: 2.25rem;
	height: 2.25rem;

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;

		object: {
			fit: contain;
			position: center;
		}
	}
}
