@use './../abstracts' as *;

.arrow-back {
	display: grid;
	grid-template-columns: 24px 1fr;
	gap: 0 .5rem;

	&__back-label {
		display: none;

		font-size: 1rem;
		font-weight: $medium;

		@include breakpoint-min-lg {
			display: block;
		}
	}

	&__title {
		@include breakpoint-min-lg {
			font-size: 2rem;

			grid-column: 1 / -1;
		}

	}

	&__subtitle {
		grid-column: 2 / 2;
		// grid-row: 2 / 2;

		@include breakpoint-min-lg {
			grid-column: 1/-1;
		}
	}

	> :nth-child(3) {
		@include breakpoint-min-lg {
			margin-top: 2rem;
		}
	}
}