@use './../abstracts' as *;

$bg-color: var(--white);
$animation-timing: 0.6s;
$animation-type: ease-in-out;

// sheet bottom
$bottom-border-radius: 1.25rem 1.25rem 0 0;
$bottom-box-shadow: 0 -2px 20px 0 rgba(38, 38, 125, 0.3);
$bottom-padding-bottom: 3rem;
$bottom-padding-x: 1.5rem;
$bottom-top-space: 3.3125rem;

$bottom-lg-mw: 620px;
$bottom-lg-h: 100vh;
$bottom-lg-border-radius: 0 1.25rem 1.25rem 0;

// sheet header
$header-font-size: 0.875rem;

$header-padding-top: 1.5rem;
$header-padding-bottom: 1.125rem;
$header-padding-x: $bottom-padding-x;

// sheet cover
$cover-min-height: 15.5625rem;

// sheet body
$body-margin-top: 1.1875rem;
$body-margin-y: 2.3125rem;
$body-padding-x: $bottom-padding-x;
$body-min-height: 20vh;

// sheet title
$title-padding-x: $bottom-padding-x;
$title-margin-top: 2.0938rem;

// sheet footer
$footer-padding-x: $bottom-padding-x;

.sheet {
	background-color: $bg-color;

	&-wrapper {
		display: flex;
		align-items: flex-end;

		width: 100%;
		height: 100%;

		position: fixed;
		top: 0;
		z-index: 9999;

		transition: transform $animation-timing $animation-type;

		&.sheet-bottom {
			transform: translateY(100%);

			@include breakpoint-min-lg {
				transform: translateX(-100%);
			}

			.sheet {
				width: 100%;
				height: calc(100vh - $bottom-top-space);

				padding-bottom: $bottom-padding-bottom;
				margin-top: $bottom-top-space;

				border-radius: $bottom-border-radius;

				display: flex;
				flex-direction: column;

				@include breakpoint-max-lg {
					box-shadow: $bottom-box-shadow;
				}

				@include breakpoint-min-lg {
					max-width: $bottom-lg-mw;
					height: $bottom-lg-h;

					border-radius: $bottom-lg-border-radius;
				}
			}
		}

		&.sheet-open {
			transform: translateY(0);

			@include breakpoint-min-lg {
				transform: translateX(0);
			}
		}
	}

	&-header {
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
		align-items: center;
		font-size: $header-font-size;

		padding: {
			top: $header-padding-top;
			bottom: $header-padding-bottom;
			left: $header-padding-x;
			right: $header-padding-x;
		}

		>* {
			display: flex;
			align-items: center;
		}

		.sheet-header {
			&-start {
				text-align: left;
				justify-content: start;
				order: 1;
			}

			&-center {
				text-align: center;
				justify-content: center;
				order: 2;
			}

			&-end {
				text-align: right;
				justify-content: end;
				order: 3;
			}
		}
	}

	&-cover {
		img {
			width: 100%;
			height: auto;
			min-height: $cover-min-height;

			object-fit: cover;
			object-position: center;

			aspect-ratio: 130 / 83;
		}

		+.sheet-title {
			margin-top: $title-margin-top;
		}
	}

	&-title {
		padding: {
			left: $title-padding-x;
			right: $title-padding-x;
		}

		+.sheet-body {
			margin-top: 1.5rem;
		}
	}

	&-body {
		flex: 1;
		overflow: auto;

		min-height: $body-min-height;

		margin: {
			top: $body-margin-top;
			bottom: calc($body-margin-y / 2);
		}
	}

	&-footer {
		padding: {
			left: $footer-padding-x;
			right: $footer-padding-x;
		}
	}

	&-sheet-close-btn {
		--bs-btn-font-size: .875rem;

		@include breakpoint-min-lg {
			--bs-btn-font-size: 1rem;
		}
	}

	&-backdrop {
		display: none;

		@include breakpoint-min-lg {
			display: block;

			position: fixed;
			inset: 0;
			z-index: 1;

			opacity: 0;

			transition: all 0.2s ease-in-out;

			background-color: rgba(0, 0, 0, .5);

			&.open {
				opacity: 1;
			}
		}
	}
}