$p-abundance: 5.5rem;

.input-overlap {
    position: relative;

    &-container {
        display: grid;
        place-items: center;

        position: absolute;
        top: 0;
        bottom: 0;

        &:first-child {
            left: .5rem;
        }

        &:last-child {
            right: .5rem;
        }
    }

    >input:first-child {
        padding-right: $p-abundance;
    }

    >input:last-child {
        padding-left: $p-abundance;
    }
}