@use './../abstracts' as *;

main {
    padding-block: 1.5rem;

    @include breakpoint-min-lg {
        padding-block: 3rem;
    }

    &.lg-center-container {
        @include breakpoint-min-lg {
            display: grid;
            place-items: center;
        }
    }
}