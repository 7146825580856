@use './../abstracts' as *;

$form-icon-color: map-get($main-colors, secondary);
$form-icon-padding-left: 3rem;

.form {
	&-icon {
		.icon {
			height: 100%;
			aspect-ratio: 1;

			display: flex;
			align-items: center;
			justify-content: center;

			position: absolute;
			top: 0;
			left: 0;

			pointer-events: none;

			color: $form-icon-color;
		}

		&.form-floating label,
		.form-control {
			padding-left: $form-icon-padding-left;
			transform-origin: 2.5rem 0;
		}

		.form-control[disabled] {
			&~.icon {
				opacity: 0.72;
			}
		}
	}
}