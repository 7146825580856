@use './../abstracts' as *;

$statuses: (
    "success": map-get($main-colors, "success"),
    "error": map-get($main-colors, "error")
);

$fs: 1rem;
$gap: .25rem;
$label-fs: .75rem;

.form-message {
    display: flex;
    align-items: center;
    gap: $gap;

    font-size: $fs;

    >*:not(.form-message-label) {
        font-size: inherit !important;
    }

    &-label {
        font-size: $label-fs;
    }

    @each $status,
    $value in $statuses {
        &.#{$status}-message {
            color: $value
        }
    }
}