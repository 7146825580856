@use './../abstracts' as *;

$tooltip-bg: map-get($main-colors, "secondary");
$tooltip-padding-x: .75rem;
$tooltip-padding-y: .5rem;
$tooltip-border-radius: .1875rem;
$tooltip-ff: $site-font-family;
$tooltip-fs: .75rem;
$tooltip-line-height: 1.834;

.tooltip {
    --bs-tooltip-bg: #{$tooltip-bg};
    --bg-tooltip-padding-x: #{$tooltip-padding-x};
    --bg-tooltip-padding-y: #{$tooltip-padding-y};
    --bs-tooltip-border-radius: #{$tooltip-border-radius};

    font-family: $site-font-family;
    font-size: $tooltip-fs;

    line-height: $tooltip-line-height;
}